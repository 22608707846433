<template>
	
<div class="actions" :data-status="status">

	<div class="actions-status">

		<template v-if="isValid">

			<template v-if="!isSaved"><i class="fa fa-exclamation-circle" />You have not saved your marksheet.</template>
			<template v-if="isChanged && isSaved"><i class="fa fa-exclamation-circle" />You have not saved your changes to the marksheet.</template>
			<template v-if="!isChanged && isSaved"><i class="fa fa-check-circle" />You have saved your marksheet.</template>

		</template>

		<template v-if="!isValid">

			<template v-if="!isSaved"><i class="fa fa-times-circle" />You must complete all required elements before being able to save.</template>
			<template v-if="isSaved"><i class="fa fa-times-circle" />You must complete all required elements before being able to save your changes.</template>

		</template>

	</div>

	<div class="actions-buttons">

        <app-button v-on:click="$emit('reset')" v-if="isSaved && isChanged" theme="plain" class="sheet-foot-button">Undo</app-button>
		<app-button v-if="isChanged || !isSaved" v-on:click="$emit('save')" :disabled="!isValid || (!isChanged && isSaved)" :loading="isSaving" theme="white" class="sheet-foot-button">{{ saveButtonText }}</app-button>
		<app-button v-if="!$isInStandardisation && !hidenext" v-on:click="$emit('next')"  :disabled="!isValid" :loading="isSaving" theme="white" class="sheet-foot-button">{{ saveNextButtonText }}</app-button>

	</div>

</div>

</template>

<script>	

export default {

	props: ['isSaving', 'isSaved', 'isChanged', 'hidenext', 'isValid'],

	computed: {

		status: function() {

			if (!this.isValid) {
				
				return 'invalid'
				
			} else if (this.isChanged) {

				return (this.isSaved) ? 'changed' : 'unsaved'

			} else {

				return (this.isSaved) ? 'saved' : 'unsaved'

			}

		},

		saveButtonText: function() {

			if (this.isChanged) {

				return (this.isSaved) ? 'Save' : 'Save' 

			} else {

				return (this.isSaved) ? 'Saved' : 'Save' 

			}

		},

		saveNextButtonText: function() {

			if (this.isChanged) {

				return (this.isSaved) ? 'Save & Next' : 'Save & Next' 

			} else {

				return (this.isSaved) ? 'Next' : 'Save & Next' 

			}

		}

	}

}

</script>

<style scoped>

.actions {
	flex-shrink: 0;
	height: 48px;
	padding: 4px;
	display: flex;
	justify-content: space-between;
	background-color: #fff;
	border-radius: 0px 0px 4px 4px;
}

.actions[data-status="saved"] {
	background-color: #38b56a;
}

.actions[data-status="changed"] {
	background-color: #FBB516;
}

.actions[data-status="invalid"],
.actions[data-status="unsaved"] {
	background-color: #c62323;
}

.actions-status {
	line-height: 20px;
	padding: 0px 6px;
	font-weight: 16px;
	font-weight: 500;
	display: flex;
	align-items: center;
}

.actions[data-status="saved"] .actions-status {
	color: #fff;
}

.actions[data-status="changed"] .actions-status {
	color: #333;
}

.actions[data-status="invalid"] .actions-status,
.actions[data-status="unsaved"] .actions-status {
	color: #fff;
}

.actions-status .fa {
	margin-right: 10px;
	font-size: 24px;
}

.actions-buttons {
	display: flex;
	justify-content: flex-end;
}

.actions-buttons >>> .button {
	width: 160px;
	margin-left: 10px;
	height: 40px;
	color: #333;
}

.actions-buttons >>> .button.theme-plain {
	background-color: rgba(0, 0, 0, 0.1);
}

.actions-buttons >>> .button.theme-plain:hover {
	background-color: rgba(0, 0, 0, 0.25);
	color: #fff;
}

.actions-buttons >>> .button.theme-white {
	color: #333;
}

.actions-buttons >>> .button.theme-white:hover {
    background-color: #287ABD;
    color: #fff;
}

</style>