import store from '../store'
import pubsub from './pubsub'
import monitor from './monitor'
import _ from 'underscore'
import constants from '../constants'
import {connect} from 'twilio-video'

export default {

	identity: '',
	config: {
		name: '',
		region: 'ie1',
		dominantSpeaker: false,
		audio: true,
		video: {
			width: 320
		},
		preferredVideoCodecs: [
			{ codec: 'VP8', simulcast: true }
		],
		networkQuality: {
			local: 1,
			remote: 1
		},
		bandwidthProfile: {
			video: {
				mode: 'grid',
				dominantSpeakerPriority: 'high',
				trackSwitchOffMode: 'detected'
			}
		}
	},
	room: false,
	timer: false,

	isConnected: false,
	isSetup: false,
	
	setup: function() {

		this.config.name += store.getters['session/chaperone']

		if (store.getters['people/self'].role === constants.role.observer) store.commit('chaperone/chaperone', store.getters['session/chaperoneWith'])

		window.addEventListener('beforeunload', function() {

			this.stopTalking()
			this.disconnect(true)

		}.bind(this))

		pubsub.$on('localtrackchange', this.disconnect.bind(this))

		this.isSetup = true

	},

	startTalking: async function() {

		if (!this.isConnected) await this.connect()

		if (store.getters['people/self'].role === constants.role.observer) {

			store.commit('chaperone/talking', true)

			this.room.localParticipant.audioTracks.forEach(function(publication) {

				if (!publication.isTrackEnabled) publication.track.enable()

			}.bind(this))

			this.room.localParticipant.videoTracks.forEach(function(publication) {

				if (!publication.isTrackEnabled) publication.track.enable()

			}.bind(this))

		}

	},

	stopTalking: function() {

		if (store.getters['people/self'].role === constants.role.observer && this.isConnected) {

			store.commit('chaperone/talking', false)

			this.room.localParticipant.audioTracks.forEach(function(publication) {

				if (publication.isTrackEnabled) publication.track.disable()

			}.bind(this))

			this.room.localParticipant.videoTracks.forEach(function(publication) {

				if (publication.isTrackEnabled) publication.track.disable()

			}.bind(this))

		}

	},

	connect: function() {

		//var config = _.clone(this.config)

		this.config.audio = {
			deviceId: store.getters['device/audio/current']
		}

		this.config.video.deviceId = store.getters['device/video/current']

		if (store.getters['device/ios']) this.config.tracks = store.getters['device/tracks']

		return new Promise(function(resolve) {
	
			if (!this.isConnected) {

				if ((store.getters['event/chaperones/enabled'] && store.getters['people/self'].role === constants.role.candidate) || store.getters['people/self'].chaperone) {

					if(!this.isSetup) this.setup()

					var startTime = Date.now()
					
					connect(store.getters['session/token'], this.config).then(function(room) {

						var waitTime = Date.now() - startTime
	
						monitor.log('user/room/join', {
							duration: waitTime,
							room: this.config.name
						})
	
						this.room = room

						console.log('chaperone', store.getters['session/chaperone'], 'connected')

						this.timer = _.delay(function() {
							
							this.disconnect()

						}.bind(this), 10800000)

						this.isConnected = true

						this.stopTalking()

						this.room.localParticipant.on('networkQualityLevelChanged', function(value) {

							if (value !== null) monitor.log('user/strength', {
								strength: value,
								room: store.getters['session/chaperone']
							})

						})

						if (this.room.localParticipant.networkQualityLevel !== null) monitor.log('user/strength', {
							strength: this.room.localParticipant.networkQualityLevel,
							room: store.getters['session/chaperone']
						})

						room.participants.forEach(function(participant) {

							if (participant.identity === store.getters['chaperone/chaperone']) {

								store.commit('chaperone/person', participant)

								if (store.getters['people/self'].role === constants.role.candidate) {

									participant.videoTracks.forEach(function(track) {
										if (track.isTrackEnabled) store.commit('chaperone/talking', true)
			
									})

								}

							}
				
						}.bind(this))

						room.on('participantConnected', function(participant) {

							if (participant.identity === store.getters['chaperone/chaperone']) {
									
								store.commit('chaperone/person', participant)

								if (store.getters['people/self'].role === constants.role.candidate) {

									participant.videoTracks.forEach(function(track) {

										if (track.isTrackEnabled) store.commit('chaperone/talking', true)
			
									})

								}

							}

						}.bind(this))

						room.on('participantReconnected', function(participant) {

							if (participant.identity === store.getters['chaperone/chaperone']) {
								
								store.commit('chaperone/person', participant)

								if (store.getters['people/self'].role === constants.role.candidate) {

									participant.videoTracks.forEach(function(track) {

										if (track.isTrackEnabled) store.commit('chaperone/talking', true)
			
									})

								}

							}

						}.bind(this))

						room.on('participantDisconnected', function(participant) {

							if (participant.identity === store.getters['chaperone/chaperone']) {

								store.commit('chaperone/person', false)
								store.commit('chaperone/talking', false)

							}

						}.bind(this))

						room.on('trackEnabled', function(track, participant) {

							if (store.getters['people/self'].role === constants.role.candidate && participant.identity === store.getters['chaperone/chaperone']) {
									
								store.commit('chaperone/person', participant)
								store.commit('chaperone/talking', true)

							}

						}.bind(this))

						room.on('trackDisabled', function(track, participant) {

							if (store.getters['people/self'].role === constants.role.candidate && participant.identity === store.getters['chaperone/chaperone']) {
									
								store.commit('chaperone/talking', false)
								store.commit('chaperone/chaperone', false)

							}

						}.bind(this))
							
						room.on('disconnected', this.onDisconnect.bind(this))

						resolve()

					}.bind(this))

				} else {

					resolve()

				}

			} else {

				resolve()

			}

		}.bind(this))

	},

	disconnect: function(unload) {

		unload = unload || false

		if (this.room) this.room.disconnect(null, unload)

	},

	onDisconnect: function(e, unload) {

		clearTimeout(this.timer)

		unload = unload || false

		store.commit('chaperone/person', false)
		store.commit('chaperone/talking', false)

		console.log('chaperone', store.getters['session/chaperone'], 'disconnected')
	
		this.isConnected = false

		if (!unload) this.connect()

	}

}