<template>

  <app-actions-icons class="actions" :class="{'is-disabled': isPaused, [clsCount]: true}" v-if="canDoSomething">

    <app-actions-icon :shortcut="['shift', 'c']" icon="chalkboard-teacher" name="Flag as calibration case"
                      v-if="canFlagCalibration && !$hasFlagged('calibration', isActive.identity, selfIdentity)"
                      v-on:click="onFlagCalibration" :loading="is.flaggingCalibration"/>
    <app-actions-icon :shortcut="['shift', 'c']" icon="chalkboard-teacher"
                      :highlight="$hasFlagged('calibration', isActive.identity, selfIdentity)"
                      name="Cancel flag as calibration case"
                      v-if="canFlagCalibration && $hasFlagged('calibration', isActive.identity)"
                      v-on:click="onFlagCalibration" :loading="is.flaggingCalibration"/>

    <app-actions-icon :shortcut="['shift', 'f']" icon="flag" name="Flag as potential re-run"
                      v-if="canFlagRerun && !$hasFlagged('rerun', isActive.identity, selfIdentity)"
                      v-on:click="onFlagRerun" :loading="is.flaggingRerun"/>
    <app-actions-icon :shortcut="['shift', 'f']" icon="flag"
                      :highlight="$hasFlagged('rerun', isActive.identity, selfIdentity)"
                      name="Cancel flag as potential re-run"
                      v-if="canFlagRerun && $hasFlagged('rerun', isActive.identity)" v-on:click="onFlagRerun"
                      :loading="is.flaggingRerun"/>

    <app-actions-icon :shortcut="['shift', 'f']" icon="flag"
                      :highlight="$hasFlagged(['case', 'conflict', 'inconsistency', 'technical', 'unable', 'training'], isActive.identity)"
                      name="Flag as issue" v-if="canFlagExaminer" v-on:click="onFlagExaminer"
                      :options="flagExaminerOptions"/>

    <app-actions-icon :shortcut="['shift', 'f']" icon="hand-paper" name="Request assistance"
                      v-if="canFlagTalk && !$isFlagging('assistance')" v-on:click="onFlagTalk"
                      :loading="is.flaggingTalk"/>
    <app-actions-icon :shortcut="['shift', 'f']" icon="hand-paper" :highlight="$isFlagging('assistance')"
                      name="Cancel request for assistance" v-if="canFlagTalk && $isFlagging('assistance')"
                      v-on:click="onFlagTalk" :loading="is.flaggingTalk"/>

    <app-actions-icon :shortcut="['shift', 'f']" icon="id-card-alt" name="Click to set as ID verified"
                      v-if="canFlagVerified && !$hasFlagged('verified', $store.getters['chaperone/chaperone'])"
                      v-on:click="onFlagVerify" :loading="is.flaggingVerify"/>
    <app-actions-icon :shortcut="['shift', 'f']" icon="id-card-alt" :highlight="true" name="ID verified"
                      v-if="canFlagVerified && $hasFlagged('verified', $store.getters['chaperone/chaperone'])"
                      v-on:click="onFlagVerify" :loading="is.flaggingVerify"/>

    <app-actions-icon :disabled="!$isActiveReady" :shortcut="(isCandidate) ? ['shift', 'b'] : ['shift', 'alt', 'c']"
                      :icon="candidateIcon" :initial="(isCandidate) ? false : 'C'" :name="candidateName"
                      v-if="canReadCandidateBrief" v-on:click="onCandidateBrief"/>
    <app-actions-icon :disabled="!$isActiveReady" :shortcut="(isRoleplayer) ? ['shift', 'b'] : ['shift', 'alt', 'r']"
                      :icon="roleplayerIcon" :initial="(isRoleplayer) ? false : 'R'"
                      :options="getBriefOptions('roleplayer')" :name="roleplayerName" v-if="canReadRoleplayerBrief"
                      v-on:click="onRoleplayerBrief"/>
    <app-actions-icon :disabled="!$isActiveReady" :shortcut="(isExaminer) ? ['shift', 'b'] : ['shift', 'alt', 'e']"
                      :icon="examinerIcon" :initial="(isExaminer) ? false : 'E'" :options="getBriefOptions('examiner')"
                      :name="examinerName" v-if="canReadExaminerBrief" v-on:click="onExaminerBrief"/>
    <app-actions-icon :disabled="!$isActiveReady" :shortcut="(isLayExaminer) ? ['shift', 'b'] : ['shift', 'alt', 'l']"
                      :icon="layexaminerIcon" :initial="(isLayExaminer) ? false : 'L'"
                      :options="getBriefOptions('layexaminer')" :name="layexaminerName" v-if="canReadLayExaminerBrief"
                      v-on:click="onLayExaminerBrief"/>

    <app-actions-icon :shortcut="['shift', 'h']" icon="hand-paper" name="Raise hand"
                      v-if="canRaiseHand && !isHighlighted" v-on:click="onRaiseHand" :loading="is.raising"/>
    <app-actions-icon :shortcut="['shift', 'h']" icon="hand-rock" name="Lower hand" v-if="canRaiseHand && isHighlighted"
                      v-on:click="onRaiseHand" :loading="is.raising"/>

    <app-actions-icon :shortcut="['shift', 's']" icon="user-friends"
                      :bounce="$isFlagging('assistance', participatingCandidate)"
                      :flagged="$isFlagging('assistance', participatingCandidate)"
                      :name="'Start video chat with ' + $language.role.candidate" v-if="!isTalking && canTalk"
                      v-on:click="onStartTalk"/>
    <app-actions-icon :shortcut="['shift', 'e']" icon="user-slash"
                      :name="'End video chat with ' + $language.role.candidate" v-if="isTalking && canTalk"
                      v-on:click="onStopTalk"/>

    <app-actions-icon :shortcut="['shift', 'm']" icon="microphone" name="Mute microphone"
                      v-if="!isMuted && canToggleMicrophone" v-on:click="onMute"/>
    <app-actions-icon :shortcut="['shift', 'm']" icon="microphone-slash" name="Unmute microphone"
                      v-if="isMuted && canToggleMicrophone" v-on:click="onUnmute"/>

    <app-actions-icon :shortcut="['shift', 'v']" icon="video" name="Hide camera" v-if="!isHidden && canToggleVideo"
                      v-on:click="onHide"/>
    <app-actions-icon :shortcut="['shift', 'v']" icon="video-slash" name="Show camera" v-if="isHidden && canToggleVideo"
                      v-on:click="onShow"/>

    <app-actions-icon :shortcut="['shift', 's']" icon="desktop" name="Start screenshare"
                      v-if="canScreenShare && !isScreenSharing" v-on:click="onScreenshare"/>
    <app-actions-icon :shortcut="['shift', 's']" icon="desktop" :slash="true" name="Stop screenshare"
                      v-if="canScreenShare && isScreenSharing" v-on:click="onScreenshare"/>

    <app-actions-icon :shortcut="['shift', 'n']" icon="pencil-alt" name="Notepad" v-if="canMakeNotes"
                      v-on:click="onNotepad"/>

    <app-actions-icon :shortcut="['shift', 'm']" icon="marker" name="Toggle marking panel" v-if="canMark"
                      v-on:click="onMarking"/>

    <app-actions-icon :shortcut="['shift', 'u']" icon="users" name="Toggle attendee panel" v-if="canUsers"
                      v-on:click="onUsers"/>

    <app-actions-icon :shortcut="['shift', 'e']" icon="clock" name="Extend running time" v-if="canExtend"
                      v-on:click="onExtend"/>

    <app-actions-icon :shortcut="['shift', 'j']" :bounce="$isFlagging('assistance', participatingCandidate)"
                      :flagged="$isFlagging('assistance', participatingCandidate)" icon="user-plus"
                      :name="participateName" v-if="canParticipate" v-on:click="onParticipate"
                      :loading="is.participate"/>

    <app-actions-icon :shortcut="['shift', 'o']" icon="user-secret" name="Back to observing only" v-if="canObserve"
                      v-on:click="onObserve" :loading="is.observe"/>

    <app-actions-icon :shortcut="['shift', 'l']" icon="door-open" :loading="is.leave"
                      :name="($isActivePlayback) ? 'Leave recording' : 'Leave room'" v-if="canLeave"
                      v-on:click="onLeave"/>

    <app-actions-icon :shortcut="['shift', 'n']" icon="book" name="Open BNF" v-if="canLinkBNF" v-on:click="onBNFClick"/>

    <app-actions-icon :shortcut="['shift', 'r']" icon="eye-slash" name="Reveal marks to all examiners"
                      v-if="canFlagReveal && !$_.contains(isActive.meta.revealed, isActive.meta.marksheet)"
                      v-on:click="onFlagReveal" :loading="is.flaggingReveal"/>
    <app-actions-icon :shortcut="['shift', 'r']" icon="eye" name="Cancel reveal marks to all examiners"
                      v-if="canFlagReveal && $_.contains(isActive.meta.revealed, isActive.meta.marksheet)"
                      v-on:click="onFlagReveal" :loading="is.flaggingReveal"/>

  </app-actions-icons>

</template>

<script>

export default {

  data: function () {

    return {
      is: {
        flaggingReveal: false,
        flaggingCalibration: false,
        flaggingTalk: false,
        flaggingAbsent: false,
        flaggingVerify: false,
        flaggingRerun: false,
        participate: false,
        observe: false,
        leave: false,
        raising: false
      }
    }

  },

  computed: {

    participatingCandidate: function () {

      if (this.$store.getters['chaperone/chaperone']) {

        return this.$store.getters['chaperone/chaperone']

      }

      if (this.isActiveStation) {

        return this.isActive.candidate

      } else {

        return null

      }

    },

    clsCount: function () {

      var count = 0

      if (this.canMark) count++
      if (this.canParticipate) count++
      if (this.canObserve) count++
      if (this.canToggleVideo) count++
      if (this.canToggleMicrophone) count++
      if (this.canScreenShare) count++
      if (this.canReadCandidateBrief) count++
      if (this.canReadRoleplayerBrief) count++
      if (this.canReadExaminerBrief) count++
      if (this.canReadLayExaminerBrief) count++
      if (this.canMakeNotes) count++
      if (this.canTalk) count++
      if (this.canLeave) count++
      if (this.canRaiseHand) count++
      if (this.canUsers) count++
      if (this.canExtend) count++
      if (this.canFlagTalk) count++
      if (this.canFlagVerified) count++
      if (this.canFlagExaminer) count++
      if (this.canFlagRerun) count++
      if (this.canFlagCalibration) count++
      if (this.canFlagReveal) count++
      if (this.canLinkBNF) count++

      return 'count-' + count.toString() + ' rows-' + Math.ceil(count / 5).toString()

    },

    canDoSomething: function () {

      return this.canLinkBNF ||
          this.canFlagRerun ||
          this.canFlagReveal ||
          this.canFlagCalibration ||
          this.canFlagExaminer ||
          this.canFlagVerified ||
          this.canFlagTalk ||
          this.canExtend ||
          this.canRaiseHand ||
          this.canUsers ||
          this.canScreenShare ||
          this.canToggleVideo ||
          this.canTalk ||
          this.canMark ||
          this.canParticipate ||
          this.canObserve ||
          this.canToggleMicrophone ||
          this.canReadLayExaminerBrief ||
          this.canReadExaminerBrief ||
          this.canReadCandidateBrief ||
          this.canReadRoleplayerBrief ||
          this.canMakeNotes ||
          this.canLeave

    },

    canLinkBNF: function () {

      return this.isCandidate && this.$store.getters['event'].candidates.bnf

    },

    canFlagExaminer: function () {

      return (this.isExaminer || this.isLayExaminer) && this.$isActivePlayback && this.$store.getters['session/lead/section'] !== this.$constants.lead.section.standardisation

    },

    canFlagTalk: function () {

      return this.isCandidate && this.$isEventChaperoned && !this.isTalking && this.$isFlagEnabled(this.$constants.flag.assistance)

    },

    canFlagRerun: function () {

      return this.isRoleplayer && this.$isFlagEnabled(this.$constants.flag.rerun) && !this.$isPreparation && this.isActive

    },

    canFlagReveal: function () {

      return (this.isActive) ? this.$isLeadExaminer && this.isActive.meta.marksheet : false

    },

    canFlagCalibration: function () {

      return this.$isLeadExaminer && this.isActiveStation && !this.$isActivePlayback

    },

    canFlagVerified: function () {

      return this.isChaperone && !this.isRoamingChaperone && this.$isEventChaperoned && !this.isActive

    },

    canScreenShare: function () {

      var canShare = this.isLead || this.isRoamingObserver

      if (this.isActiveStation) {

        if (this.$store.getters['schedule/active/permissions'].screenshare === 2) {

          canShare = this.isLead || this.isCandidate || this.isRoamingObserver

        }

      }

      return !this.$isActivePlayback && this.isParticipating && canShare && (this.isActiveMeeting || this.$isWebinar || (this.isActiveStation && !this.isPreparation)) && this.$store.getters['schedule/active/permissions'].screenshare && (!this.$store.getters['room/screensharing'] || this.$store.getters['device/screen'])

    },

    isScreenSharing: function () {

      return this.$store.getters['device/screen']

    },

    canTalk: function () {

      return this.isChaperone && (this.$store.getters['schedule'].length > 1 || !this.isRoamingChaperone) && (!this.isActiveStation || this.isPreparation) && !this.isActiveMeeting && !this.isChaperoneGroup && !this.$isEventFinished

    },

    isTalking: function () {

      return this.$store.getters['chaperone/talking']

    },

    isHighlighted: function () {

      return (this.isActiveMeeting) ? this.$_.contains(this.isActive.highlighted, this.$store.getters['people/self'].identity) : false

    },

    canMark: function () {

      return (this.isExaminer || this.isLayExaminer) && (!this.isActiveStation || this.time < this.active.roleplay || (!this.isActive.candidate && this.$store.getters['event/marking/empty'])) && this.$isMarkingEnabled && !this.$isEventFinished && this.$isExam

    },

    canToggleVideo: function () {

      return this.isParticipating && this.isActiveMeeting && this.$store.getters['schedule/active/permissions'].togglevideo

    },

    canToggleMicrophone: function () {

      return !this.$isActivePlayback && this.isParticipating && (this.isActiveMeeting || (this.isActiveStation && !this.isPreparation))

    },

    canRaiseHand: function () {

      return this.isObserving && this.$isWebinar

    },

    canExtend: function () {

      return this.hasPermission(this.$constants.observer.permission.extend) && (this.isActiveStation || this.isActiveMeeting) && !this.$isActivePlayback

    },

    canUsers: function () {

      return this.isLead && this.$isWebinar

    },

    candidateIcon: function () {

      return (this.isCandidate) ? 'file-alt' : 'file'

    },

    roleplayerIcon: function () {

      return (this.isRoleplayer) ? 'file-alt' : 'file'

    },

    examinerIcon: function () {

      return (this.isExaminer) ? 'file-alt' : 'file'

    },

    layexaminerIcon: function () {

      return (this.isLayExaminer) ? 'file-alt' : 'file'

    },

    participateName: function () {

      return (this.$isWebinar) ? 'Speaker ONLY participate in video chat' : 'Participate in video chat'

    },

    candidateName: function () {

      return (this.isCandidate) ? 'My Brief' : this.$language.role.candidate + ' brief'

    },

    roleplayerName: function () {

      return (this.isRoleplayer) ? 'My Brief' : this.$language.role.roleplayer + ' brief' + ((this.canReadRoleplayerBrief > 1) ? 's' : '')

    },

    examinerName: function () {

      return (this.isExaminer) ? 'My Brief' : this.$language.role.examiner + ' brief' + ((this.canReadExaminerBrief > 1) ? 's' : '')

    },

    layexaminerName: function () {

      return (this.isLayExaminer) ? 'My Brief' : this.$language.role.layexaminer + ' brief' + ((this.canReadLayExaminerBrief > 1) ? 's' : '')

    },

    active: function () {

      return this.$store.getters['schedule/active']

    },

    canMakeNotes: function () {

      return this.isActiveStation && this.isCandidate && this.$store.getters['event/notes/enabled'] && !this.isPreparation

    },

    canObserve: function () {

      return this.isParticipating && (this.isObserver || this.isChaperone) && !this.$isInterval

    },

    canParticipate: function () {

      return !this.$isActivePlayback && this.isObserving && (this.isObserver || this.isChaperone) && (this.isActiveMeeting || (this.isActiveStation && !this.isPreparation))

    },

    canLeave: function () {

      return this.$isActivePlayback || ((this.isRoamingObserver && (this.$store.getters['schedule/next'] || this.isActive)) || (this.isRoamingChaperone && this.isActive && this.$store.getters['schedule/active'].type !== this.$constants.schedule.type.chaperone))

    },

    canReadCandidateBrief: function () {

      return (this.isActiveStation && !this.$isActivePlayback) && !this.isPreparation && this.$_.contains(this.isActive.brief, 'candidate')

    },

    canReadExaminerBrief: function () {

      return ((this.isActiveStation || this.$isInStandardisation || ((this.$isEventMarking && this.time >= this.$store.getters['schedule'][0].start - 86400) && (this.$isExaminer || this.$isLeadExaminer))) && !this.isPreparation) ? this.getBriefOptions('examiner').length : false

    },

    canReadLayExaminerBrief: function () {

      return ((this.isActiveStation && !this.$isActivePlayback) && !this.isPreparation) ? this.getBriefOptions('layexaminer').length : false

    },

    canReadRoleplayerBrief: function () {

      return ((this.isActiveStation && !this.$isActivePlayback) && !this.isPreparation) ? this.getBriefOptions('roleplayer').length : false

    },

    isActiveStation: function () {

      return (this.active) ? this.active.type === this.$constants.schedule.type.station : false

    },

    isActiveMeeting: function () {

      return (this.active) ? this.active.type === this.$constants.schedule.type.meeting : false

    },

    isMuted: function () {

      return !this.$store.getters['settings/audio']

    },

    isHidden: function () {

      return !this.$store.getters['settings/video']

    },

    flagExaminerOptions: function () {

      var options = []

      options.push({
        text: 'Case Issue',
        value: 'case',
        highlight: this.$hasFlagged('case', this.isActive.identity, this.selfIdentity)
      })

      options.push({
        text: 'Marking Conflict',
        value: 'conflict',
        highlight: this.$hasFlagged('conflict', this.isActive.identity, this.selfIdentity)
      })

      options.push({
        text: 'Potential Training Case',
        value: 'training',
        highlight: this.$hasFlagged('training', this.isActive.identity, this.selfIdentity)
      })

      options.push({
        text: 'Roleplayer Inconsistency',
        value: 'inconsistency',
        highlight: this.$hasFlagged('inconsistency', this.isActive.identity, this.selfIdentity)
      })

      options.push({
        text: 'Technical Issue',
        value: 'technical',
        highlight: this.$hasFlagged('technical', this.isActive.identity, this.selfIdentity)
      })

      options.push({
        text: 'Unable to Complete',
        value: 'unable',
        highlight: this.$hasFlagged('unable', this.isActive.identity, this.selfIdentity)
      })

      return options

    }

  },

  created: function () {

    this.checkFinished()

  },

  mounted() {
    new Promise((resolve) => {
      // As this application is going to be re-written we are using a quick and dirty trick to get vue to re-render
      // the component so that the buttons display properly.
      setTimeout(() => {
        resolve();
      }, 250); // Simulate async operation with setTimeout
    }).then(() => {
      // Ensures $forceUpdate is called after the simulated async operation has completed
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    });

  },

  watch: {

    active: {

      deep: true,

      handler: function () {

        this.checkFinished()

      }

    }

  },

  methods: {

    onBNFClick: function () {

      window.open('https://bnf.nice.org.uk/', '_blank').focus()

    },

    onFlagReveal: function () {

      this.is.flaggingReveal = true

      this.$api.request('standardisation/reveal', {
        room: this.isActive.identity,
        identity: this.isActive.meta.marksheet
      }).then(function () {

        this.is.flaggingReveal = false

      }.bind(this), function () {

        this.is.flaggingReveal = false

      }.bind(this))

    },

    onFlagCalibration: function () {

      this.is.flaggingCalibration = true

      this.$api.request('flag/calibration', {
        identity: this.isActive.identity
      }).then(function () {

        this.is.flaggingCalibration = false

      }.bind(this), function () {

        this.is.flaggingCalibration = false

      }.bind(this))

    },

    onFlagRerun: function () {

      this.is.flaggingRerun = true

      this.$api.request('flag/rerun', {
        identity: this.isActive.identity
      }).then(function () {

        this.is.flaggingRerun = false

      }.bind(this), function () {

        this.is.flaggingRerun = false

      }.bind(this))

    },

    onFlagExaminer: function (value) {

      this.$pubsub.$emit('modal.flag', {
        identity: this.isActive.identity,
        type: value,
      })

    },

    onFlagAbsent: function () {

      this.is.flaggingAbsent = true

      this.$api.request('flag/absent', {
        identity: this.isActive.identity
      }).then(function () {

        this.is.flaggingAbsent = false

      }.bind(this), function () {

        this.is.flaggingAbsent = false

      }.bind(this))

    },

    onFlagVerify: function () {

      this.is.flaggingVerify = true

      this.$api.request('flag/verified', {
        identity: this.$store.getters['chaperone/chaperone']
      }).then(function () {

        this.is.flaggingVerify = false

      }.bind(this), function () {

        this.is.flaggingVerify = false

      }.bind(this))

    },

    onFlagTalk: function () {

      this.is.flaggingTalk = true

      this.$api.request('flag/assistance').then(function () {

        this.is.flaggingTalk = false

      }.bind(this), function () {

        this.is.flaggingTalk = false

      }.bind(this))

    },

    getBriefOptions: function (name) {

      var schedules = [this.isActive]

      if (!this.isActive && this.$isEventMarking) {

        schedules = (this.$isLeadExaminer) ? this.$store.getters['schedule'] : [this.$store.getters['schedule'][0]]

      }

      var options = []

      this.$_.each(schedules, function (schedule) {

        var briefs = this.$_.filter(schedule.brief, function (type) {
          return type.indexOf(name) === 0
        })

        this.$_.each(briefs, function (key) {

          options.push({
            text: (this.$isLeadExaminer && !this.isActive && this.$isEventMarking) ? schedule.name : key.replace('_', ' '),
            value: (this.$isLeadExaminer && !this.isActive && this.$isEventMarking) ? schedule.identity : key
          })

        }.bind(this))

      }.bind(this))

      return options

    },

    onStartTalk: function () {

      this.$api.request('observer/video', {
        start: true,
        identity: (this.isRoamingChaperone) ? this.active.candidate : this.$store.getters['session/chaperoneWith']
      }).then(function () {

        if (this.isRoamingChaperone) {

          this.$store.commit('chaperone/chaperone', this.active.candidate)

        }

        this.$chaperone.startTalking()

      }.bind(this))

    },

    onStopTalk: function () {

      this.$api.request('observer/video', {
        identity: (this.isRoamingChaperone) ? this.active.candidate : this.$store.getters['session/chaperoneWith']
      }).then(function () {

        if (this.isRoamingChaperone) {

          this.$store.commit('chaperone/chaperone', false)


        }

        this.$chaperone.stopTalking()

      }.bind(this))

    },

    checkFinished: function () {

      if ((this.isRoamingObserver || this.isRoamingChaperone) && !this.isActive) {

        this.$store.commit('session/observer/section', this.$constants.observer.schedule)

        if (!this.isRoamingChaperone && this.$store.getters['schedule'].length === 1 && !this.$store.getters['schedule/next']) this.onLeave()

      }

    },

    onMarking: function () {

      this.$pubsub.$emit('marking.toggle')

    },

    onUsers: function () {

      this.$pubsub.$emit('users.toggle')

    },

    onExtend: function () {

      this.$pubsub.$emit('extend.toggle')

    },

    onParticipate: function () {

      if (!this.is.observe) {

        this.is.participate = true

        this.$api.request('room/participate', {
          room: this.isActive.identity
        }).then(function () {

          this.$store.commit('settings/video', true)
          this.$store.commit('settings/audio', true)

          this.is.participate = false

        }.bind(this), function () {

          this.is.participate = false

        }.bind(this))

      }

    },

    onObserve: function () {

      if (!this.is.observe) {

        this.is.observe = true

        this.$api.request('room/observe', {
          room: this.isActive.identity
        }).then(function () {

          this.$store.commit('settings/video', false)
          this.$store.commit('settings/audio', false)

          this.is.observe = false

        }.bind(this), function () {

          this.is.observe = false

        }.bind(this))

      }

    },

    onLeave: async function () {

      if (this.$isActivePlayback) {

        this.$store.commit('schedule/remove', this.isActive)

      } else {

        if (!this.is.leave && this.$store.getters['schedule'].length) {

          if (this.isRoamingChaperone) {

            this.$chaperone.stopTalking()

            this.$chaperone.disconnect(false, true)

          }

          this.is.leave = true

          this.$api.request('room/leave', {
            room: (this.isRoamingChaperone || !this.isActive) ? this.$store.getters['schedule/last'].identity : this.isActive.identity
          }).then(function () {

            this.$store.commit('settings/video', false)
            this.$store.commit('settings/audio', false)

            this.is.leave = false

          }.bind(this), function () {

            this.is.leave = false

          }.bind(this))

        }

      }

    },

    onScreenshare: function () {

      this.$store.dispatch('device/screenshare')

    },

    onHide: function () {

      this.$store.commit('settings/video', false)

    },

    onShow: function () {

      this.$store.commit('settings/video', true)

    },

    onMute: function () {

      this.$store.commit('settings/audio', false)

    },

    onUnmute: function () {

      this.$store.commit('settings/audio', true)

    },

    onNotepad: function () {

      this.$pubsub.$emit('action.notepad')

    },

    onCandidateBrief: function () {

      this.$pubsub.$emit('action.brief', 'candidate')

    },

    onRoleplayerBrief: function (value) {

      this.$pubsub.$emit('action.brief', value)

    },

    onExaminerBrief: function (value) {

      if (this.$isActivePlayback) {

        window.open(process.env.VUE_APP_API + 'shredded/station/' + this.isActive.station + '/examiner_1.pdf?forcePdf=1&key=' + this.$store.getters['session/key'], '_blank').focus()

      } else {

        this.$pubsub.$emit('action.brief', value)

      }

    },

    onLayExaminerBrief: function (value) {

      this.$pubsub.$emit('action.brief', value)

    },

    onRaiseHand: function () {

      this.is.raising = true

      this.$api.request('room/highlight', {
        room: this.isActive.identity
      }).then(function () {

        this.is.raising = false

      }.bind(this), function () {

        this.is.raising = false

      }.bind(this))

    }

  }

}

</script>

<style scoped>

.actions.is-disabled {
  opacity: 0.35;
  pointer-events: none;
}

.actions {
  flex-wrap: wrap;
}

.actions >>> .icon {
  border-radius: 0px;
  margin-right: 0px;
  border-right-width: 0px;
  width: 20%;
  flex-shrink: 0;
  line-height: 44px !important;
}

.actions >>> .icon:nth-child(1) {
  border-top-left-radius: 4px;
}

.actions.rows-1 >>> .icon:nth-child(1) {
  border-bottom-left-radius: 4px;
}

.actions >>> .icon:nth-child(6),
.actions >>> .icon:nth-child(7),
.actions >>> .icon:nth-child(8),
.actions >>> .icon:nth-child(9),
.actions >>> .icon:nth-child(10) {
  border-top-width: 0px;
}

.actions >>> .icon:nth-child(5) {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-right-width: 1px;
}

.actions.count-10 >>> .icon:nth-child(5) {
  border-bottom-right-radius: 0px;
}

.actions >>> .icon:nth-child(6) {
  border-bottom-left-radius: 4px;
}

.actions >>> .icon:last-child {
  border-bottom-right-radius: 4px;
  border-right-width: 1px;
}

.actions.rows-2 >>> .icon:last-child {
  width: calc(20% + 1px);
}

.actions.rows-1 >>> .icon:last-child {
  border-top-right-radius: 4px;
  width: 20%;
}

.actions.count-10 >>> .icon:last-child {
  width: 20%;
}

</style>
