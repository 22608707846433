<template>
	
<div class="marksheet" :style="style">

	<com-resize />

	<com-questions :locked="isLocked" :questions="questions" :sheet="sheet.session" :standardisation="sheet.standardisation" v-on:change="onChange" />

	<com-actions v-if="!isLocked" :is-saving="is.saving" :is-saved="isSaved" :is-changed="isChanged" :is-valid="isValid" v-on:reset="onReset" v-on:save="onSave" v-on:next="onSave(true)" />

</div>

</template>

<script>	

import Vue from 'vue'

import comResize from './marksheet/Resize'
import comQuestions from './marksheet/Questions'
import comActions from './marksheet/Actions'

export default {

	props: ['marksheet'],

	components: {
		'com-resize': comResize,
		'com-questions': comQuestions,
		'com-actions': comActions
	},

	data: function() {

		return {
			is: {
				saving: false
			},
			sheet: {
				session: {},
				saved: {},
				standardisation: {}
			},
			wasStandardisation: false,
			lastIdentity: false,
			timer: {
				autosave: false,
				standardisation: false
			}
		}

	},

	created: function() {

		var saved = JSON.parse(JSON.stringify(this.$store.getters['marking/saved']))
		var session = JSON.parse(JSON.stringify(this.$store.getters['marking/session']))

		Vue.set(this.sheet, 'saved', saved[this.identity])
		Vue.set(this.sheet, 'session', session[this.identity])
		Vue.set(this.sheet, 'standardisation', {})

		this.lastIdentity = this.identity

		if (this.$isInStandardisation) {

			this.wasStandardisation = true

			this.onStandardisation()

		} 

	},

	beforeDestroy: function() {

		clearTimeout(this.timer.autosave)
		clearTimeout(this.timer.standardisation)

		if(!this.wasStandardisation) {

			this.onAutosave()

		}

	},

	watch: {

		revealedString: function() {

			this.onStandardisation()

		}

	},

	computed: {

		isLocked: function() {

			return this.$isRoamingExaminer && !this.$isInStandardisation && !this.$_.contains(this.playback, this.identity)

		},

		playback: function() {

			return this.$_.pluck(this.$store.getters['playback'], 'identity')

		},

		revealed: function() {

			return (this.$isInStandardisation) ? this.isActive.meta.revealed : []

		},

		revealedString: function() {

			return (this.$isInStandardisation) ? this.isActive.meta.revealed.join('.') : ''

		},

		isChanged: function() {

			return !this.$_.isEqual(this.sheet.saved, this.sheet.session)

		},

		isSaved: function() {

			return this.validateAnswers('saved')

		},

		isValid: function() {

			return this.validateAnswers('session')

		},

		identity: function() {

			return (this.marksheet) ? this.marksheet : this.isActive.identity

		},

		questions: function() {

            return this.$store.getters['marking/questions'][this.identity]

		},

		style: function() {

			var height = this.$store.getters['marking/height']

			return {
				height: height.toString() + 'px'
			}

		}

	},

	methods: {

		validateAnswers: function(type) {

			return this.$validateSheet(this.$store.getters['marking/questions'][this.identity], this.sheet[type])

		},

		onStandardisation: function() {
			
			if (this.$isLead || this.$_.contains(this.isActive.meta.revealed, this.isActive.meta.marksheet)) {

				clearTimeout(this.timer.standardisation)

				this.$api.request('standardisation/status', {
					identity: this.lastIdentity,
					room: this.isActive.identity
				}).then(function(json) {

					Vue.set(this.sheet, 'standardisation', json.sheets)

					this.timer.standardisation = this.$_.delay(this.onStandardisation.bind(this), 5000)

				}.bind(this))

			} else {

				Vue.set(this.sheet, 'standardisation', {})

				this.timer.standardisation = this.$_.delay(this.onStandardisation.bind(this), 5000)

			}

		},

		onAutosave: function() {

			if (this.$isRoamingObserver) return false

			if(!this.wasStandardisation) {

				clearTimeout(this.timer.autosave)

				this.$api.request('marking/save', {
					identity: this.lastIdentity,
					standardisation: this.$isInStandardisation,
					marks: this.sheet.session,
					autosave: true
				})

			}

		},

		onReset: function() {

			if (this.$isRoamingObserver) return false

			clearTimeout(this.timer.autosave)

			Vue.set(this.sheet, 'session', JSON.parse(JSON.stringify(this.sheet.saved)))

			this.onAutosave()

		},

		onSave: function(next) {

			next = next || false

			if (this.$isRoamingObserver) return false

			if (next && !this.isChanged) {

				this.is.saving = false

				this.toNext()

				return false

			}

			clearTimeout(this.timer.autosave)

			this.is.saving = true

			this.$api.request('marking/save', {
                identity: this.identity,
				standardisation: this.$isInStandardisation,
				marks: this.sheet.session,
				autosave: false
            }).then(function() {

				Vue.set(this.sheet, 'saved', JSON.parse(JSON.stringify(this.sheet.session)))

				if(!this.$isInStandardisation) {

					this.$store.commit('marking/marks', {
						identity: this.identity,
						marks: this.sheet.saved
					})

				}

				console.log(this.$store.getters['marking/saved'])
				console.log(this.$store.getters['marking/session'])

				if(next) {

					this.toNext()
					
				} else {

					this.is.saving = false

				}
				
            }.bind(this), function() {

                this.is.saving = false

            }.bind(this))

		},

		toNext: function() {

            this.is.saving = true

			var playbackIndex = this.$_.findLastIndex(this.$store.getters['playback'], {
				identity: this.isActive.identity
			}) + 1

			if (playbackIndex < this.$store.getters['playback'].length) {

				this.$api.request('playback/start', {
					identity: this.$store.getters['playback'][playbackIndex].identity
				}).then(function(json) {

					this.$store.commit('schedule/remove', this.isActive)

					this.$nextTick(function() {

						this.$store.commit('schedule/add', this.$util.copy(json.schedule))

					}.bind(this))

				}.bind(this), function() {

					this.$store.commit('schedule/remove', this.isActive)

				}.bind(this))

			} else {

				this.$store.commit('schedule/remove', this.isActive)

			}

		},

		onChange: function(e) {

			if (this.$isRoamingObserver) return false

			if (e.value !== this.sheet.session[e.identity]) {

				clearTimeout(this.timer.autosave)

				Vue.set(this.sheet.session, e.identity, e.value)

				if(!this.$isInStandardisation) {

					this.$store.commit('marking/session', {
						identity: this.identity,
						marks: this.sheet.session
					})

					this.timer.autosave = this.$_.delay(this.onAutosave.bind(this), 5000)

				}
				
			}

		}

	}

}

</script>

<style scoped>

.marksheet {
	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
	border: 1px solid #ddd;
	border-radius: 4px;
	background-color: #fff;
	margin-top: 10px;
	min-height: 48px;
	max-height: calc(100% - 102px);
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
}

</style>