<template>

<app-page>

	<div class="login-prompt is-browser" v-if="!is.compatible">

		<h1>Incompatible browser</h1>

		<p>To run Osler Online you must be using Google Chrome and a desktop computer.</p>
		
		<p>Please click the button below to go to the official Google Chrome website and download the latest version.</p>

		<a href="https://www.google.com/chrome">Get Chrome</a>

	</div>

	<div class="login-prompt is-auth" v-if="is.compatible" :class="{'is-session': is.reload}">

		<div class="auth-logo prp-loading"></div>

		<template v-if="!is.reload">

			<p v-if="is.errored" class="auth-error">Sorry, your details were not recognised.</p>

			<input type="text" ref="name" class="auth-input" v-model="inputs.name" :disabled="is.loading" v-on:keydown.enter="onSubmitClick" placeholder="Enter your username" />

			<input type="password" class="auth-input" v-model="inputs.code" :disabled="is.loading" v-on:keypress.enter="onSubmitClick" placeholder="Enter your access pin" />

			<app-button :loading="is.loading" v-on:click="onSubmitClick">Login</app-button>
			
			<a class="login-statement" href="https://www.rcgp.org.uk/terms-conditions/accessibility-statement" target="_blank">accessibility statement</a>

		</template>

	</div>

</app-page>

</template>

<script>

export default {

	data: function() {

		return {
			is: {
				compatible: true,
				loading: false,
				errored: false,
				reload: false,
				quick: false
			},
			inputs: {
				name: '',
				code: '',
				event: 'dashboard'
			},
			startTime: false,
			modules: [
				'language', 
				'onboarding', 
				'session', 
				'event', 
				'people', 
				'circuits', 
				'schedule', 
				'structure',
				'overview', 
				'chaperone', 
				'incidents', 
				'marking', 
				'messages', 
				'chats', 
				'stations', 
				'announcements', 
				'playback', 
				'time', 
				'online',
				'settings',
				'flags'
			]
		}

	},

	created: async function() {

		if (this.is.compatible) {
			
			var url = window.location.href.split('/')
			var key = false

			if (url.length > 3 && url[3] !== '') {

				url = url[3].split('?')

				this.inputs.event = url[0]
				key = url[1]
				this.is.quick = url.length > 1

			} 

			await this.$store.dispatch('init', this.inputs.event)

			if (key && key !== 'quick') {

				await this.$store.dispatch('cookie', {
					event: this.inputs.event,
					key: key
				})

				window.history.replaceState(null, null, window.location.href.split('?')[0])

			}

			if (this.$store.getters['key']) {

				this.is.reload = true

				this.$store.commit('session/reload', true)

				this.$api.request('login', {
					mode: this.loginMode,
					event: this.inputs.event
				}).then(this.onLoginSuccess.bind(this), this.onLoginFail.bind(this))

			} 
			
		}

	},

	computed: {

		loginMode: function() {

			if (this.inputs.event !== 'dashboard') {

				return (this.window.is.desktop) ? this.$constants.mode.event : this.$constants.mode.assistant
				
			} else {

				return this.$constants.mode.dashboard

			}

		}

	},

	methods: {

		onSubmitClick: function() {

			this.is.loading = true

			this.startTime = Date.now()

			this.$api.request('login', {
				mode: this.loginMode,
				event: this.inputs.event,
				name: this.inputs.name,
				code: this.inputs.code
			}).then(this.onLoginSuccess.bind(this), this.onLoginFail.bind(this))

		},

		onLoginFail: function() {

			var waitTime = Date.now() - this.startTime

			this.$monitor.log('user/api/error', {
				endpoint: 'login',
				duration: waitTime
			})

			this.$store.commit('session/reload', false)
			this.$store.commit('key', null)

			this.is.loading = false
			this.is.errored = true
			this.is.reload = false

		},

		onLoginSuccess: function(json) {

			if (json.session.mode === this.$constants.mode.dashboard) {

				this.$store.dispatch('cookie', {
					event: 'dashboard',
					key: json.session.key
				})

				this.$store.dispatch('user/init', json.user)
				this.$store.dispatch('user/availability/init', json.availability)
				this.$store.dispatch('user/schedule/init', json.schedule)

				this.$store.dispatch('session/init', json.session)

				this.$store.commit('session/presence', true)

			} else {

				var waitTime = Date.now() - this.startTime

				this.$monitor.log('user/api/ok', {
					endpoint: 'login',
					duration: waitTime
				})

				this.$store.dispatch('cookie', {
					event: this.inputs.event,
					key: json.session.key
				})

				// update store with data and subscribe to any pusher events
				this.$_.each(this.modules, function(name) {

					this.$store.dispatch(name + '/init', json[name])

				}.bind(this))

				this.$store.dispatch('online/presence').then(function() {

					if (this.is.quick) {
						
						this.$store.dispatch('device/video').then(function() {

							return this.$store.dispatch('device/audio')

						}.bind(this)).then(function() {

							this.$store.commit('session/accepted', true)
							this.$store.commit('device/initiated', true)

							this.$api.request('login/ready')

							window.history.pushState({}, '', window.location.href.replace('?quick', ''))
							
							this.$store.commit('session/presence', true)

						}.bind(this))


					} else {

						this.$store.commit('session/presence', true)

					}

				}.bind(this))

			}

		}

	}

}

</script>

<style scoped>

.login-prompt {
	width: 320px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: #fff;
	padding: 20px;
	border: 1px solid #ddd;
	border-radius: 4px;
	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
}

.login-prompt.is-session {
	opacity: 0;
}

.auth-logo {
    animation-name: none;
    margin-bottom: 10px;
}

.auth-input {
	border: 2px solid #ddd;
	border-radius: 4px;
	font-size: 14px;
	line-height: 38px;
	padding: 0px 10px;
	text-align: center;
	background-color: #fff;
	margin-bottom: 10px;
	width: 100%;
}

.auth-input:focus {
	outline: none;
    border: 2px solid #4285f4;
}

.auth-error {
	font-size: 14px;
	line-height: 20px;
	margin-bottom: 10px;
	font-weight: 400;
	color: red;
}

.login-prompt.is-browser h1 {
	font-size: 24px;
	margin-bottom: 20px;
}

.login-prompt.is-browser p {
	font-size: 16px;
	line-height: 20px;
	color: #333;
	margin-bottom: 20px;
}

.login-prompt.is-browser a {
	display: block;
	border-radius: 4px;
	font-size: 14px;
	line-height: 40px;
	font-weight: 400;
	cursor: pointer;
	padding: 0px 20px;
	text-transform: uppercase;
	background-color: #287ABD;
	color: #fff;
}

.login-prompt.is-browser a:hover {
	background-color: #1b649e;
}

.login-statement {
	position: absolute;
	bottom: -40px;
	font-size: 12px;
	color: #428dca;
	font-weight: 400;
}

.login-statement:hover,
.login-statement:focus {
	color: #287ABD;
	text-decoration: underline;
}

</style>
