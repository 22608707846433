<template>

<div class="stream" :class="{'is-local': isLocal, 'is-hidden': isHidden && ready}" ref="stream">

	<div class="stream-watermark" :class="{'is-muted': isMuted, 'is-speaking': isSpeaking}" v-if="watermark && !isLocal">{{ watermark }}</div>

	<div class="stream-screen" ref="screen" v-show="screen || (localScreen && isLocal)">

	</div>

	<div class="stream-person" ref="person" v-show="!screen && (!localScreen || !isLocal) && !isHidden">

	</div>

	<div class="stream-muted" v-if="isMuted && !localMute && ready && !miniview && !noMuted" v-tooltip="'Audio muted'" v-on:click="onMutedClick"><i class="fa fa-microphone-slash"></i></div>

	<div class="stream-hidden" v-if="isHidden && ready && !audioOnly && !isAvatar && !miniview"><i class="fa fa-video-slash"></i></div>

	<div class="stream-hidden" v-if="isHidden && ready && audioOnly && !isAvatar && !miniview"><i class="fa fa-phone"></i></div>

	<div class="stream-avatar" :style="{backgroundImage: 'url(' + avatar + ')'}" v-if="isAvatar">abc</div>

	<div class="stream-speaking" v-if="isSpeaking && ready && !miniview"><i class="fa fa-microphone"></i></div>

	<div class="stream-quality" v-if="(!isMuted || !isHidden) && qualityScore && !miniview" v-tooltip="'Connection quality'" :class="{[classQuality]: true}">
		<div></div>
		<div></div>
		<div></div>
		<div></div>
		<div></div>
	</div>

</div>

</template>

<script>

export default {

	props: ['stream', 'watermark', 'speaking', 'localMute', 'forced', 'showScreenshare', 'miniview', 'noMuted', 'avatar', 'audioOnly'],

	data: function() {

		return {
			ready: false,
			audio: false,
			video: false,
			screen: false,
			audioElement: false,
			quality: 0
		}

	},

	computed: {
		
		isAvatar: function() {

			return this.avatar && !this.forced

		},

		localVideo: function() {

			return this.$store.getters['device/video']

		},

		localScreen: function() {

			return (this.showScreenshare) ? this.$store.getters['device/screen'] : false

		},

		isLocal: function() {

			return this.stream.identity === this.$store.getters['session/identity']

		},

		isMuted: function() {

			if (this.forced) return false
			if (this.localMute) return true

			return (this.isLocal) ? !this.$store.getters['settings/audio'] : !this.audio

		},

		isHidden: function() {

			if (this.forced) return false

			return (this.isLocal) ? !this.$store.getters['settings/video'] : !this.video

		},

		isSpeaking: function() {

			if (this.localMute) return false

			return this.speaking

		},

		qualityScore: function() {

			return (this.isLocal) ? this.$store.getters['room/quality'] : this.quality

		},

		classQuality: function() {

			return 'quality-' + this.qualityScore.toString() 

		}

	},

	watch: {

		audio: function(n) {

			this.$emit('audio', n)

		},

		audioElement: function(n) {

			if (n && this.localMute) this.audioElement.muted = this.localMute

		},

		localMute: function(muted) {

			if (this.audioElement) this.audioElement.muted = muted
			
		},

		localVideo: {

			deep: true,

			handler: function(n) {

				if (n) this.attachLocalVideo(n)

			}

		},

		localScreen: {

			deep: true,

			handler: function(n) {

				if (n) this.attachLocalScreen(n)

			}

		}

	},

	created: function() {

		this.$prefix = ['stream', this.stream.identity]

		this.$nextTick(function() {

			if (this.isLocal) {

				this.$log('local')

				if (this.localVideo) this.attachLocalVideo(this.localVideo)
				if (this.localScreen && this.showScreenshare) this.attachLocalScreen(this.localScreen)

			} else {

				this.$log('remote', this.stream)

				this.stream.tracks.forEach(function(publication) {

					if (publication.isSubscribed) {

						this.attach(publication)
					
					}

				}.bind(this))

				this.onNetworkQualityLevelChange(this.stream.networkQualityLevel)
				
				this.stream.on('trackPublished', this.onTrackPublished.bind(this))
				this.stream.on('trackUnpublished', this.onTrackUnpublished.bind(this))
				this.stream.on('trackSwitchedOn', this.onTrackSwitchedOn.bind(this))
				this.stream.on('trackSwitchedOff', this.onTrackSwitchedOff.bind(this))
				this.stream.on('trackSubscribed', this.onTrackSubscribed.bind(this))
				this.stream.on('trackUnsubscribed', this.onTrackUnsubscribed.bind(this))
				this.stream.on('trackEnabled', this.onTrackEnabled.bind(this))
				this.stream.on('trackDisabled', this.onTrackDisabled.bind(this))
				this.stream.on('networkQualityLevelChanged', this.onNetworkQualityLevelChange.bind(this))

			}

			this.ready = true

		})

	},

	beforeDestroy: function() {

		if (!this.isLocal) {

			this.stream.removeListener('trackPublished', this.onTrackPublished.bind(this))
			this.stream.removeListener('trackUnpublished', this.onTrackUnpublished.bind(this))
			this.stream.removeListener('trackSwitchedOn', this.onTrackSwitchedOn.bind(this))
			this.stream.removeListener('trackSwitchedOff', this.onTrackSwitchedOff.bind(this))
			this.stream.removeListener('trackSubscribed', this.onTrackSubscribed.bind(this))
			this.stream.removeListener('trackUnsubscribed', this.onTrackUnsubscribed.bind(this))
			this.stream.removeListener('trackEnabled', this.onTrackEnabled.bind(this))
			this.stream.removeListener('trackDisabled', this.onTrackDisabled.bind(this))
			this.stream.removeListener('networkQualityLevelChanged', this.onNetworkQualityLevelChange.bind(this))

		}

	},

	methods: {

		attachLocalVideo: function(track) {

			this.$log('track.attach', track)

			var el = track.attach()

			this.$emit('attached', el)
			
			this.$refs.person.appendChild(el)

		},

		attachLocalScreen: function(track) {

			this.$log('track.attach', track)

			var el = track.attach()

			this.$emit('attached', el)
			
			this.$refs.screen.appendChild(el)

		},

		attach: function(publication) {

			this.$log('track.attach', publication)

			if (publication.trackName === 'screenshare') {

				this.$emit('screenStart')

				this.screen = true

				if (publication.track) this.$refs.screen.appendChild(publication.track.attach())

			} else {
				
				if (publication.kind === 'audio') this.audio = publication.isTrackEnabled
				if (publication.kind === 'video') this.video = publication.isTrackEnabled

				if (publication.kind !== 'video' || !this.isAvatar) {

					var el = publication.track.attach()

					if (publication.kind === 'audio') this.audioElement = el

					this.$refs.person.appendChild(el)

				}

			}

		},

		detach: function(publication) {

			this.$log('track.detatch', publication)

			if (publication.trackName === 'screenshare') {

				this.$emit('screenEnd')

				this.screen = false

			} else {

				if (publication.kind === 'audio') this.audio = false
				if (publication.kind === 'video') this.video = false

				if (publication.kind === 'audio') this.audioElement = false

			}

			if (publication.detach !== undefined) {

				publication.detach().forEach(function(el) {

					el.remove()
					el.srcObject = null

				}.bind(this))

			}

		},

		onTrackSwitchedOn: function(track, publication) {

			this.$log('track.switchedon', publication)

		},

		onTrackSwitchedOff: function(track, publication) {

			this.$log('track.switchedoff', publication)

		},

		onTrackPublished: function(publication) {

			this.$log('track.published', publication)
			this.attach(publication)

		},

		onTrackUnpublished: function(publication) {

			this.$log('track.unpublished', publication)
			this.detach(publication)

		},

		onTrackSubscribed: function(track, publication) {

			this.$log('track.subscribed', publication)
			this.attach(publication)

		},

		onTrackUnsubscribed: function(track, publication) {

			this.$log('track.unsubscribed', publication)
			this.detach(publication)

		},

		onTrackEnabled: function(publication) {

			this.$log('track.enabled', publication)

			if (publication.kind === 'audio') this.audio = true
			if (publication.kind === 'video') this.video = true

		},

		onTrackDisabled: function(publication) {

			this.$log('track.disabled', publication)

			if (publication.kind === 'audio') this.audio = false
			if (publication.kind === 'video') this.video = false

		},

		onMutedClick: function() {

			if (this.isLocal) {

				this.$store.commit('settings/audio', true)

			}

		},

		onNetworkQualityLevelChange: function(level) {

			this.$log('quality.change', level)

			this.quality = level

		}

	}

}

</script>

<style scoped>

.stream {
	width: 100%;
	height: 100%;
	background-color: #000;
	background-image: url('data:image/gif;base64,R0lGODlhKwALAPEAAAAAAP///3x8fP///yH+GkNyZWF0ZWQgd2l0aCBhamF4bG9hZC5pbmZvACH5BAAKAAAAIf8LTkVUU0NBUEUyLjADAQAAACwAAAAAKwALAAACMoSOCMuW2diD88UKG95W88uF4DaGWFmhZid93pq+pwxnLUnXh8ou+sSz+T64oCAyTBUAACH5BAAKAAEALAAAAAArAAsAAAI9xI4IyyAPYWOxmoTHrHzzmGHe94xkmJifyqFKQ0pwLLgHa82xrekkDrIBZRQab1jyfY7KTtPimixiUsevAAAh+QQACgACACwAAAAAKwALAAACPYSOCMswD2FjqZpqW9xv4g8KE7d54XmMpNSgqLoOpgvC60xjNonnyc7p+VKamKw1zDCMR8rp8pksYlKorgAAIfkEAAoAAwAsAAAAACsACwAAAkCEjgjLltnYmJS6Bxt+sfq5ZUyoNJ9HHlEqdCfFrqn7DrE2m7Wdj/2y45FkQ13t5itKdshFExC8YCLOEBX6AhQAADsAAAAAAAAAAAA=');
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

.stream-watermark {
	position: absolute;
	z-index: 1002;
	color: #fff;
	font-weight: 400;
	opacity: 0.5;
	font-size: 12px;
	pointer-events: none;
	padding: 5px;
	transition: none;
	animation: aniWatermark 40s infinite step-end;
}

.stream-watermark.is-muted {
	animation: aniWatermarkMuted 30s infinite step-end;
}

.stream-watermark.is-speaking {
	animation: aniWatermarkSpeaking 30s infinite step-end;
}

.card.is-pinned .stream-watermark {
	font-size: 20px!important;
	padding: 10px!important;
}

.grid.size-1 .stream-watermark,
.grid.size-1.5 .stream-watermark,
.grid.size-2 .stream-watermark {
	font-size: 20px!important;
	padding: 10px!important;
}

@keyframes aniWatermark {
	0% { top: 0%; left: 0%; right: auto; bottom: auto; }
	25% { top: 0%; left: auto; right: 20px; bottom: auto; }
	50% { bottom: 0%; left: auto; right: 0%; top: auto; }
	75% { bottom: 0%; left: 0%; right: auto; top: auto;}
}

@keyframes aniWatermarkMuted {
	0% { top: 0%; left: 0%; right: auto; bottom: auto; }
	33% { top: 0%; left: auto; right: 20px; bottom: auto; }
	66% { bottom: 0%; left: 0%; right: auto; top: auto;}
}

@keyframes aniWatermarkSpeaking {
	0% { top: 0%; left: 0%; right: auto; bottom: auto; }
	33% { top: 0%; left: auto; right: 20px; bottom: auto; }
	66% { bottom: 0%; left: auto; right: 0%; top: auto; }
}

.stream.is-hidden {
	background-color: #287ABD;
	display: flex;
	justify-content: center;
	align-items: center;
	background-image: none;
}

.stream-screen,
.stream-person {
	position: absolute;
	left: 0px;
	top: 0px;
	background-color: #000;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.stream-person {
	z-index: 2;
}

.stream-person.is-small {
	width: 96px;
	height: 96px;
	left: 0px;
	top: 0px;
	border-radius: 4px 0px 4px 0px;
	overflow: hidden;
}

.stream >>> video {
	width: auto;
	z-index: 1;
	display: block;
	height: 100%;
	max-height: 100%;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
}

.stream.is-hidden >>> video {
	visibility: hidden;
}

.stream-speaking {
	position: absolute;
	z-index: 3000;
	left: 10px;
	bottom: 10px;
	font-size: 16px;
	width: 32px;
	height: 32px;
	background-color: #287ABD;
	color: #fff;
	line-height: 32px;
	text-align: center;
	border-radius: 16px;
}

.stream-muted {
	position: absolute;
	z-index: 3000;
	right: 10px;
	bottom: 10px;
	font-size: 16px;
	width: 32px;
	height: 32px;
	background-color: #c62323;
	color: #fff;
	line-height: 32px;
	text-align: center;
	border-radius: 16px;
}

.is-local .stream-muted {
	cursor: pointer;
}

.stream-hidden {
	font-size: 96px;
	color: #fff;
}

.stream-avatar {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	background-color: #000;
	z-index: 1000;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: contain;
}

.stream-quality {
	position: absolute;
	right: 0px;
	top: 0px;
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	padding: 4px;
	width: 22px;
	z-index: 1001;
	border-radius: 0px 4px 0px 4px;
	height: 23px;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.5);
}

.stream-quality > div {
	width: 2px;
	background-color: rgba(255, 255, 255, 0.1);
	margin-right: 1px;
	transition: all 300ms ease-in-out;
}

.stream-quality > div:last-child {
	margin-right: 0px;
}

.stream-quality.quality-1 > div:nth-child(1),
.stream-quality.quality-2 > div:nth-child(1),
.stream-quality.quality-3 > div:nth-child(1),
.stream-quality.quality-4 > div:nth-child(1),
.stream-quality.quality-5 > div:nth-child(1),
.stream-quality.quality-2 > div:nth-child(2),
.stream-quality.quality-3 > div:nth-child(2),
.stream-quality.quality-4 > div:nth-child(2),
.stream-quality.quality-5 > div:nth-child(2),
.stream-quality.quality-3 > div:nth-child(3),
.stream-quality.quality-4 > div:nth-child(3),
.stream-quality.quality-5 > div:nth-child(3),
.stream-quality.quality-4 > div:nth-child(4),
.stream-quality.quality-5 > div:nth-child(4),
.stream-quality.quality-5 > div:nth-child(5) {
	background-color: rgba(255, 255, 255, 1);
}

.stream-quality > div:nth-child(1) {
	height: 3px;
}

.stream-quality > div:nth-child(2) {
	height: 6px;
}

.stream-quality > div:nth-child(3) {
	height: 9px;
}

.stream-quality > div:nth-child(4) {
	height: 12px;
}

.stream-quality > div:nth-child(5) {
	height: 15px;
}

</style>