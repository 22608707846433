<template>
    
<div class="sheet" :class="{'is-incomplete': !isValid, 'is-complete': isValid, 'is-saved': isSaved && !isChanged}">

	<div class="sheet-head-status">
		<div class="sheet-head-status-item is-completed" v-if="!isValid">{{ required.total - required.answered }} question<template v-if="required.total - required.answered !== 1">s</template> unanswered</div>
		<div class="sheet-head-status-item is-completed" v-if="isValid">All questions answered<i class="fa fa-check"></i></div>
	</div>

    <div class="sheet-body">

        <com-question v-for="question in questions" :question="question" :value="sheet[question.identity]" :key="question.identity" v-on:change="onQuestionChange" />

    </div>

	<com-foot :hide-clock="true" :hide-credit="true" class="sheet-foot">

		<div class="sheet-foot-buttons">

			<app-button v-on:click="onResetClick" v-if="isSaved && isChanged" theme="plain" class="sheet-foot-button">Reset changes</app-button>
			<app-button v-on:click="onSaveClick" :disabled="!isValid || (!isChanged && isSaved)" :loading="is.saving" theme="white" class="sheet-foot-button">{{ buttonText }}</app-button>

		</div>

	</com-foot>

</div>

</template>

<script>

import Vue from 'vue'

import comQuestion from './sheet/Question'
import comFoot from './../../Foot'

export default {

	props: ['candidate', 'identity', 'saved', 'session', 'validSave'],

    components: {
        'com-question': comQuestion,
		'com-foot': comFoot
    },

    data: function() {

        return {
            is: {
                valid: false,
                saving: false
			},
			sheet: {}
        }

    },

    created: function() {

		this.setupCandidate()

    },

    computed: {

		isChanged: function() {

			return !this.$_.isEqual(this.saved, this.session)

		},

		isSaved: function() {

			return this.validSave

		},

		isValid: function() {

			return this.required.total === this.required.answered

		},

		answered: function() {

			var answered = []

			this.$_.each(this.sheet, function(mark, identity) {

				if (mark || mark === 0) {

					answered.push(identity)

				}

			})

			return answered

		},

        buttonText: function() {

			if (this.isChanged) {

				return (this.isSaved) ? 'Save changes' : 'Save' 

			} else {

				return (this.isSaved) ? 'Saved' : 'Save' 

			}

        },

        questions: function() {

            return this.$store.getters['marking/questions'][this.identity]

        },

        total: function() {

            return this.questions.length

		},
		
		required: function() {

			var required = {
				total: 0,
				answered: 0
			}

			this.$_.each(this.questions, function(question) {

				if (question.required) {

					required.total++

					if (this.$_.contains(this.answered, question.identity)) {

						required.answered++

					}

				}

			}.bind(this))

			return required 

		},

        date: function() {

            return this.$store.getters['event'].date

        }

    },

    methods: {

		autosave: function() {

			this.$api.request('marking/save', {
				identity: this.identity,
				marks: this.sheet,
				autosave: true
			})

		},

		setupCandidate: function() {

			this.is.saving = false

			this.sheet = (this.session) ? JSON.parse(JSON.stringify(this.session)) : {}

			this.autosave()

		},

		onResetClick: function() {

			this.sheet = (this.saved) ? JSON.parse(JSON.stringify(this.saved)) : {}

			this.$emit('change', {
				sheet: this.sheet,
				schedule: this.identity
			})

			this.autosave()

		},

        onSaveClick: function() {

            this.is.saving = true

            this.$api.request('marking/save', {
                identity: this.identity,
				marks: this.sheet,
				autosave: false
            }).then(function() {

				this.is.saving = false
				
				this.$emit('saved', {
					sheet: JSON.parse(JSON.stringify(this.sheet)),
					schedule: this.identity
				})
				
            }.bind(this), function() {

                this.is.saving = false

            }.bind(this))

        },

        onQuestionChange: function(e) {

			if (e.value !== this.sheet[e.identity]) {

				Vue.set(this.sheet, e.identity, e.value)
				
				this.$emit('change', {
					sheet: JSON.parse(JSON.stringify(this.sheet)),
					schedule: this.identity
				})

				this.autosave()
				
			}

        }

    }

}

</script>

<style scoped>

.sheet {
	display: flex;
    flex-direction: column;
}

.sheet.is-saved .sheet-foot {
    background-color: #27a258;
}

.sheet-head-status {
    flex-shrink: 0px;
    display: flex;
    flex-direction: row;
	position: fixed;
	right: 20px;
	top: 15px;
	z-index: 3;
}

.sheet-head-status-item {
    background-color: #fff;
    color: #287ABD;
    font-size: 14px;
    font-weight: 500;
    height: 24px;
	white-space: nowrap;
    line-height: 25px;
	padding: 0px 5px;
    text-align: center;
    transition: all 100ms linear;
}

.sheet-head-status-item.is-completed {
    border-radius: 4px;
}

.sheet-head-status-item .fa {
	margin-left: 6px;
}

.sheet.is-incomplete .sheet-head-status-item.is-completed {
    color: #cc3939;
}

.sheet.is-complete .sheet-head-status-item.is-completed {
    color: #27a258;
}

.sheet-context {
    padding: 10px;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    color: #fff;
    font-weight: 400;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    justify-content: space-between;
}

.sheet-body {
    flex-grow: 1;
    flex-basis: 0;
	padding-bottom: 54px;
}

.sheet-foot {
	display: flex;
	position: fixed;
	z-index: 3;
	left: 0px;
	width: 100%;
	bottom: 0px;
	height: 54px;
    flex-shrink: 0;
	background-color: #FBB516;
}

.sheet.is-saved .sheet-foot >>> .foot-status {
    color: #fff;
}

.sheet-foot-buttons {
	display: flex;
	justify-content: flex-end;
	flex-grow: 1;
	padding: 5px 20px;
}

.sheet-foot-button {
	width: 200px;
	margin-left: 5px;
}

.sheet.is-saved .sheet-foot-button {
    color: #27a258;
}

</style>