import _ from 'underscore'
import Pusher from 'pusher-js'
import constants from '@/constants'
import store from '@/store'
import api from './api'

// ensure pusher requests include credentials
Pusher.Runtime.createXHR = function () {
	var xhr = new XMLHttpRequest()
	xhr.withCredentials = true
	return xhr
}

// capture pusher logs
Pusher.log = (msg) => {
	console.log(msg)
}

// pusher instance
var instance = false

// pusher channels
var channels = {}

// fallback polling endpoints
var fallbacks = {}

// api root
var authEndpoint = document.getElementById('app').getAttribute('data-api')

var def = {

	// initiate pusher instance
	init: async function(key) {

		if (store.getters['session/mode'] === constants.mode.dashboard) return false

		return new Promise(function(resolve, reject) {
		
			instance = new Pusher(key, {
				cluster: constants.pusher.cluster,
				authEndpoint: authEndpoint + 'pusher/auth',
				auth: {
					headers: {
						Authorization: store.getters['session/key']
					}
				}
			})

			// listen to status change to handle unavailability
			instance.connection.bind("state_change", function (states) {

				if (states.previous === 'connecting' && states.current === 'unavailable') {

					def.fallbacks.start()
					
					reject()
					
				} else if (states.previous === 'connecting' && states.current === 'disconnected') {

					def.fallbacks.start()
				
					reject()

				} else if (states.current === 'failed') {

					def.fallbacks.start()

					reject()

				} else if (states.previous === 'connecting' && states.current === 'connected') {

					def.fallbacks.stop()
				
					resolve()

				}

			})

		})

	},

	// test cluster without overriding default instance
	test: function(key, cluster) {
	
		return new Promise(function(resolve) {

			var test = new Pusher(key, {
				cluster: cluster,
				authEndpoint: authEndpoint + 'pusher/auth',
				auth: {
					headers: {
						Authorization: store.getters['session/key']
					}
				}
			})

			// listen to status change to determine success
			test.connection.bind("state_change", function (states) {

				if (states.previous === 'connecting' && states.current === 'unavailable') {
					
					resolve(false)
					
				} else if (states.previous === 'connecting' && states.current === 'disconnected') {
					
					resolve(false)

				} else if (states.current === 'failed') {
					
					resolve(false)

				} else if (states.previous === 'connecting' && states.current === 'connected') {
					
					resolve(true)

				}

			})

		})

	},

	connected: function() {

		return instance.connection.state === 'connected'
	
	},

	subscribe: {

		presence: function(identity) {

			return this.do('presence-person', identity, 'presence')

		},

		server: function() {

			return this.do('server', 'global', 'server')

		},

		event: function(identity) {

			return this.do('event', identity, 'event')

		},

		observers: function(identity, name) {

			return this.do('observers', identity, name)

		},

		user: function(identity, name) {

			return this.do('user', identity, name)

		},

		inventory: function(identity) {

			return this.private('inventory', identity)

		},

		monitor: function(identity) {

			return this.private('monitor', identity)

		},

		room: function(identity, name) {

			return this.do('room', identity, name)

		},

		schedule: function(identity, name) {

			return this.do('schedule', identity, name)

		},

		circuit: function(identity, name) {

			return this.do('circuit', identity, name)

		},

		chat: function(identity, name) {

			return this.do('chat', identity, name)

		},

		marksheet: function(identity, name) {

			return this.do('marksheet', identity, name)

		},

		do: function(prefix, identity, name) {

			if (!instance) return false

			name = name || prefix + '.' + identity
			if (!channels[name]) channels[name] = instance.subscribe(prefix + '.' + identity)

			return channels[name]

		},

		private: function(prefix, identity, name) {

			if (!instance) return false

			name = 'private-' + prefix + '.' + identity
			if (!channels[name]) channels[name] = instance.subscribe('private-' + prefix + '.' + identity)

			return channels[name]

		}

	},

	unsubscribe: {

		marksheet: function(identity) {

			return this.do('marksheet', identity)

		},

		inventory: function(identity) {

			return this.private('inventory', identity)

		},

		do: function(prefix, identity, name) {

			if (!instance) return false

			name = name || prefix + '.' + identity

			if (channels[name]) {

				channels[name] = instance.unsubscribe(prefix + '.' + identity)
				delete channels[name]

			}

		},

		private: function(prefix, identity, name) {

			if (!instance) return false

			name = 'private-' + prefix + '.' + identity

			if (channels[name]) {

				instance.unsubscribe('private-' + prefix + '.' + identity)
				delete channels[name]

			}

		}

	},

	emit: {

		monitor: function(identity, event, data) {

			return this.do('monitor', identity, event, data)

		},

		inventory: function(identity, event, data) {

			return this.do('inventory', identity, event, data)

		},

		do: function(prefix, identity, event, data) {

			var name = 'private-' + prefix + '.' + identity
			data = data || {}
			channels[name].trigger('client-' + event, data)

		}

	},

	trigger: function(channel, event, params) {

		if(!instance) return false

		instance.trigger(channel, event, params)
		
	},

	on: function(channel, event, handler, fallback) {

		// bind pusher channel
		channels[channel].bind(event, handler)

		// remember polling fallback in case pusher is down
		if (fallback) def.fallbacks.add(fallback, handler)
		
		return channels[channel]

	},

	onPrivate: function(channel, event, handler) {

		channel = channel.join('.')
		channels['private-' + channel].bind('client-' + event, handler)
		
		return channels[channel]

	},

	offPrivate: function(channel, event, handler) {

		channel = channel.join('.')
		channels['private-' + channel].unbind('client-' + event, handler)
		
		return channels[channel]

	},

	// fallback handling
	fallbacks: {

		// add fallback 
		add: function(fallback, handler) {
		
			console.log('pusher/fallbacks/add', fallback.endpoint)

			// create fallback object with defaults
			fallbacks[fallback.endpoint] = {
				interval: fallback.interval || 60,
				immediate: fallback.immediate || false,
				timer: false,
				last: 0,
				handler: handler
			}

		},
	
		// start fallback polling
		start: function() {

			console.log('pusher/fallbacks/start')

			// initiate each fallback
			_.each(fallbacks, function(fallback, endpoint) {

				// set last poll time to one minute ago to avoid unncessary duplicates
				fallback.last = store.getters['time/raw'] - 60

				// if immediate call now
				if (fallback.immediate) {
				
					def.fallbacks.poll(endpoint)
				
				// otherwise queue up with a timeout
				} else {
				
					def.fallbacks.queue(endpoint)

				}

			})
		
		},

		// queue a fallback call
		queue: function(endpoint) {

			console.log('pusher/fallbacks/queue', endpoint, fallbacks[endpoint].interval)
		
			fallbacks[endpoint].timer = _.delay(function() { def.fallbacks.poll(endpoint) }, fallbacks[endpoint].interval * 1000)
		
		},

		// poll a fallback endpoint
		poll: function(endpoint) {

			console.log('pusher/fallbacks/poll', endpoint)

			// poll the endpoint
			api.request('poll/' + endpoint, {
				last: fallbacks[endpoint].last
			}).then(function(json) {

				// update last poll time
				fallbacks[endpoint].last = store.getters['time/raw']
			
				// pass any responses to handler
				_.each(json.items, function(item) {
				
					fallbacks[endpoint].handler(item)
				
				})

				// requeue fallback after success
				def.fallbacks.queue(endpoint)

			}, function() {
			
				// requeue fallback after fail
				def.fallbacks.queue(endpoint)
			
			})

		},

		// stop all fallbacks
		stop: function() {

			console.log('pusher/fallbacks/stop')

			_.each(fallbacks, function(fallback) {

				clearTimeout(fallback.timer)

			})
		
		}

	}

}

export default def