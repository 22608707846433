
import pusher from '@/service/pusher'
import monitor from '@/service/monitor'
import constants from '../constants'
import _ from 'underscore'
import Vue from 'vue'

export default {

	namespaced: true,

	state: {

		session: false,
		presence: false,
		reload: false,
		accepted: false,
		observer: {
			section: constants.observer.rollcall,
			scroll: 0
		},
		lead: {
			section: constants.lead.section.examiners
		}

	},

	getters: {

		get: function(state) {

			return state.session

		},

		mode: function(state) {

			return state.session.mode

		},

		key: function(state) {

			return state.session.key

		},

		consent: function(state) {

			return state.session.consent

		},

		chaperone: function(state) {

			return state.session.chaperone

		},

		chaperoneWith: function(state) {

			return state.session.chaperoneWith

		},

		'onboarding/enabled': function(state) {

			return (state.session) ? state.session.onboarding.enabled : false

		},

		'onboarding/check': function(state) {

			return (state.session) ? state.session.onboarding.check : false

		},

		'lead/section': function(state) {

			return state.lead.section

		},

		'observer/section': function(state) {

			return state.observer.section

		},

		'observer/scroll': function(state) {

			return state.observer.scroll

		},

		reload: function(state) {

			return state.reload

		},

		accepted: function(state) {

			return state.accepted

		},

		loggedin: function(state) {

			return state.session && state.presence

		},

		identity: function(state) {

			return (state.session) ? state.session.identity : false

		},

		pusher: function(state) {

			return (state.session) ? state.session.pusher : false

		},

		token: function(state) {

			return (state.session) ? state.session.token : false

		},

		'is/observer': function(state, getters, rootState, rootGetters) {

			return rootGetters['people/self'].role === constants.role.observer

		},

		'is/roaming/observer': function(state, getters, rootState, rootGetters) {

			return rootGetters['people/self'].role === constants.role.observer && _.contains([constants.observer.scope.event, constants.observer.scope.circuit, constants.observer.scope.circuits], rootGetters['people/self'].scope)

		}

	},

	mutations: {

		set: function(state, data) {

			Vue.set(state, 'session', data)

		},

		presence: function(state, value) {

			state.presence = value

		},

		reload: function(state, value) {

			state.reload = value

		},

		accepted: function(state, value) {

			state.accepted = value

		},

		'lead/section': function(state, value) {

			state.lead.section = value

		},

		'observer/section': function(state, value) {

			state.observer.section = value

		},

		'observer/scroll': function(state, value) {

			state.observer.scroll = value

		}

	},

	actions: {

		init: function(context, data) {
			
			context.commit('set', data)

			// initiate pusher
			pusher.init(context.getters['pusher'])

			// listen to server
			pusher.subscribe.server()

			// subscribe user channel
			pusher.subscribe.user(context.getters['identity'], 'user');

			monitor.init()

		}

	}

}